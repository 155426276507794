import {
  getCurrentInstance,
  ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableLocationModule } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function useReportDeposit() {
  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };
  const vm = getCurrentInstance().proxy;

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const rows = ref([]);
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('STT'),
      field: 'index',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartmentName',
    },
    {
      label: t('Căn hộ'),
      field: 'roomName',
    },
    {
      label: t('Giường'),
      field: 'bedName',
    },
    {
      label: t('Khách hàng'),
      field: 'contractName',
    },
    {
      label: t('Số tiền cọc'),
      field: 'initialDeposit',
      type: 'number',
    },
    {
      label: t('Điều chỉnh tăng'),
      field: 'additionDeposit',
      type: 'number',
    },
    {
      label: t('Điều chỉnh giảm'),
      field: 'reduceDeposit',
      type: 'number',
    },

    {
      label: t('Cọc còn lại'),
      field: 'remainDeposit',
      type: 'number',
    },
    {
      label: t('Lý do tăng/giảm'),
      field: 'reason',
    },

  ];

  const amount = ref(0);
  // filter
  const apartment = ref(null);
  const location = ref(null);
  const type = ref(null);
  const floor = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    filter: {},
    page: 1,
    perPage: 10000,
  });

  //   API Call

  const fetchData = () => {
    isLoading.value = true;
    store
      .dispatch('report/getReportFinanceDepositTingTong', serverParams.value)
      .then(response => {
        const {items} = response.data
        totalRecords.value = items.length;
        rows.value = items;
        isLoading.value = false;
        amount.value = items.reduce(
          (partialSum, a) => +partialSum + +a.remainDeposit,
          0,
        ) || 0;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const exportData = () => {
    store
      .dispatch('report/exportReportFinanceDepositTingTong', serverParams.value)
      .then(response => {
        vm.$router.push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.type = val.id;
    } else {
      delete filter.type;
    }
    updateParams({ filter });
  });

  return {
    item,
    amount,
    columns,
    rows,
    apartment,
    type,
    floor,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    exportData,
    t,
  };
}
