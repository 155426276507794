<template>
  <div class="room-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Danh sách cọc') }} ({{ t('Tổng') }}: {{ Number(amount).toLocaleString() }})</b-card-title>
        <crud-buttons
          :name="t('Danh sách cọc')"
          :show-import="false"
          :show-export="true"
          :enable-add="false"
          :hide-delete="true"
          @on-export="exportData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-deposit-type v-model="type" />
          </b-col>
          <b-col
            v-if="isEnableLocationModule()"
          >
            <select-location
              v-model="location"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-apartment
              v-model="apartment"
              :location="location"
            />
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-room-detail
                    class="font-weight-bold"
                    @click="onViewDetailItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span
                  v-else-if="props.column.field === 'amount'"
                  v-b-tooltip.hover
                  :title="props.row.amount != (props.row.amountReservation + props.row.amountInInvoice) ? t('Số tiền cọc trong hợp đồng đang khách tổng cọc giữ chỗ và tổng cọc trong hóa đơn') : ''"
                  variant="gradient-danger"
                  :class="props.row.amount != (props.row.amountReservation + props.row.amountInInvoice) ? 'text-danger': ''"
                >
                  {{ Number(props.row.amount).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field.includes('Deposit')">
                  {{ Number(props.row[props.column.field]).toLocaleString() }}
                </span>
                <span v-else-if="props.column.field === 'amountInInvoice'">
                  {{ Number(props.row.amountInInvoice).toLocaleString() }}
                </span>
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <span class="mt-1">{{ t('Tổng số kết quả') }}: {{ rows.length }}</span>

              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <room-detail-modal :room-id="item.id" />
  </div>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import { VueGoodTable } from 'vue-good-table';
// eslint-disable-next-line import/no-cycle
import RoomDetailModal from '@/views/property/room/detail/RoomDetailModal.vue';
import SelectDepositType from '@/views/components/SelectDepositType.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectLocation from '@/views/components/SelectLocation.vue';
import Ripple from 'vue-ripple-directive';
import useReportDeposit from './useReportDeposit';
import { props } from 'vue-prism-component';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BSpinner,
    SelectApartment,
    VueGoodTable,
    RoomDetailModal,
    SelectDepositType,
    CrudButtons,
    SelectLocation,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const {
      item,
      columns,
      amount,
      rows,
      apartment,
      type,
      floor,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchRooms,
      location,
      isEnableLocationModule,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
    } = useReportDeposit();

    return {
      item,
      amount,
      columns,
      rows,
      apartment,
      type,
      floor,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchRooms,
      location,
      isEnableLocationModule,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onViewDetailItem,
      exportData,
      t,
    };
  },

  created() {
    this.fetchData();
  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.room-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 100px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
            }
            &:nth-child(8) {
               min-width: 120px !important;
               width: 120px !important;
            }
            &:nth-child(9) {
               min-width: 100px !important;

            }
          }
        }
      }
    }
}
</style>
